import { Icon } from '@sb1/ffe-icons-react';
import { ActionButton, PrimaryButton, SecondaryButton, TertiaryButton, ExpandButton, InlineExpandButton, ShortcutButton, TaskButton, BackButton } from '@sb1/ffe-buttons-react';
import { Table, TableHead, TableBody, TableHeaderCell, TableRow, TableDataCell } from '@sb1/ffe-tables-react';
import * as React from 'react';
export default {
  Icon,
  ActionButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  ExpandButton,
  InlineExpandButton,
  ShortcutButton,
  TaskButton,
  BackButton,
  Table,
  TableHead,
  TableBody,
  TableHeaderCell,
  TableRow,
  TableDataCell,
  React
};